define([
    "knockout",
    "jquery",
    "underscore",
    "helpers/notificationHelper",
    "helpers/labelHelper",
    "helpers/routerHelper",
    "services/workorderTimesheetService",
    "services/dispatchService",
    "core/resx",
    "helpers/queryStringHelper"
],
    function (ko, jQuery, _, notificationHelper, labelHelper, routerHelper, workorderTimesheetService, dispatchService, resx, queryStringHelper) {
        "use strict";

        var viewModel = (function () {
            var self = null;

            function ctor() {
                self = this;
                //#region Properties
                self.resx = resx;
                self.labelHelper = labelHelper;
                self.dispatchProjectCode = ko.observable();
                self.dispatchDate = ko.observable();
                self.list = ko.observableArray([]);
                self.noDataText = ko.observable("");
                self.isProjectResponsible = false;
                self.subscribeSelected = undefined;
                self.reloadButton = ko.observable();

                self.selectedIds = ko.computed(function() {
                    return _.filter(_.map(self.list(),
                            function(item) {
                                if (item.selected()) {
                                    return item.Projects[0].DispatchId;
                                }
                            }),
                        function(item) {
                            return item !== undefined;
                        });
                });
                //#endregion
            }

            //#region Private Functions
            function loadData(dispatchProjectCode, dispatchDate) {
                return workorderTimesheetService.getHoursSummaryByProjectCodeAndDispatchDate(dispatchProjectCode, dispatchDate).done(function (data) {
                    data = _.map(data, function (item) {
                        item.selected = ko.observable(false);
                        return item;
                    });
                    self.list(data);
                });
            }

            //#endregion

            //#region Public Functions
            ctor.prototype.activate = function (params) {
                self.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(params.q);
                self.dispatchDate(params.dailyEntryDate);
                self.dispatchProjectCode(params.dispatchProjectCode);

                return loadData(params.dispatchProjectCode, params.dailyEntryDate);
            };

            ctor.prototype.selectEntry = function (data) {
                data.selected(!data.selected());
            };

            ctor.prototype.selectAll = function () {
                _.each(self.list(), function (item) {
                    if (!item.IsQuit && item.IsInProgress) {
                        item.selected(true);
                    }
                });
            };

            ctor.prototype.leave = function() {
                routerHelper.showLoading();

                var ids = self.selectedIds();

                dispatchService.setHasLeftDispatches(true, ids)
                    .done(function() {
                        loadData(self.dispatchProjectCode(), self.dispatchDate());
                        var callReload = self.reloadButton();
                        if (callReload && _.isFunction(callReload)) {
                            callReload();
                        }
                    })
                    .always(routerHelper.hideLoading);
            };

            //#endregion

            return ctor;
        })();

        return viewModel;
    });
