import { singleton } from "aurelia-framework";
import { Redirect } from "aurelia-router";

import { default as ko } from "knockout";
import { default as _ } from "underscore";

import { FieldServicesWorkOrderDateGroupModel } from "api/models/company/workorder/field-services-work-order-date-group-model";
import { default as resx } from "core/resx";
import { default as dateHelper } from "helpers/dateHelper";
import { default as enumHelper } from "helpers/enumHelper";
import { default as notificationHelper } from "helpers/notificationHelper";
import { default as routerHelper } from "helpers/routerHelper";
import { default as settingHelper } from "helpers/settingHelper";
import { default as defaultService } from "services/defaultService";
import { default as workOrderService } from "services/workOrderService";

@singleton()
export class FieldServiceIndex {
    public resx: any = resx;
    public routerHelper: any = routerHelper;
    public WORKORDER_STATUS: enumHelper.workOrderStatus = enumHelper.workOrderStatus();
    public cachedListPrevious: KnockoutObservableArray<any> = ko.observableArray();
    public cachedListOnGoing: KnockoutObservableArray<any> = ko.observableArray();
    public list: KnockoutObservableArray<any> = ko.observableArray();
    public loadingStatus: KnockoutObservable<any> = ko.observable({}); // True if the end of the infinite scrolling is reached for current tab
    public page: number = 1;
    public sectionShown: KnockoutObservable<number>;
    public scrollMessageText: KnockoutObservable<string> = ko.observable(resx.localize("ScrollForMore"));
    public showScrollMessage: KnockoutObservable<boolean> = ko.observable(false);
    public workOrderStatus: enumHelper.workOrderStatus | undefined = undefined;
    public workOrderStatusValues: any[] = [];

    constructor() {
        this.sectionShown = ko.observable(this.WORKORDER_STATUS.ONGOING.id);
    }

    public bindViewModel(): void {
        this.workOrderStatus = this.WORKORDER_STATUS;
        this.workOrderStatusValues = _.values(this.WORKORDER_STATUS);
    }

    public load(): JQuery.Deferred<any, any, any> {
        return this.loadData(this.sectionShown() === this.WORKORDER_STATUS.COMPLETED.id);
    }

    public loadData(previous: boolean): JQuery.Deferred<any, any, any> {
        this.page = 1;
        return this.getData(previous, this.page).done((ls: any) => {
            (previous ? this.cachedListPrevious : this.cachedListOnGoing)(ls);
            this.list(ls);
        });
    }

    public getData(previous: boolean, page: number): JQuery.Deferred<any, any, any> {
        const dfd: JQuery.Deferred<any, any, any> = jQuery.Deferred();

        routerHelper.showLoading();

        workOrderService.list(previous, page)
            .done((data: FieldServicesWorkOrderDateGroupModel[]) => {
                return dfd.resolve(this.getDataDone(data));
            })
            .fail((data: any) => {
                dfd.reject(data);
                this.disposeAll();
            }).always(() => routerHelper.hideLoading());
        return dfd;
    }

    public getDataDone(data: any): any {
        const itemCount: number = _.reduce(data,
            (aggregate: any, cur: any) => {
                return cur.WorkOrders.length + aggregate;
            },
            0);

        if (itemCount >= defaultService.getPageSize()) {
            this.loadingStatus()[this.sectionShown()] = false;
            this.initScroll();
        } else {
            this.loadingStatus()[this.sectionShown()] = true;
            this.disposeScroll();
        }

        return this.mapData(data);
    }

    public mapData(data: any): any {
        return _.map(data,
            (item: { Date: Date | string; isOpened: KnockoutObservable<boolean>; formattedDate: string }) => {
                item.isOpened = ko.observable(true);
                item.formattedDate = dateHelper.getFullTextDate(item.Date);

                return item;
            });
    }

    public disposeAll(): void {
        this.cachedListPrevious([]);
        this.cachedListOnGoing([]);
        this.list([]);
        this.disposeScroll();
    }

    public initScroll(): void {
        jQuery(document).scroll(this.scrollHandler);
        this.showScrollMessageFunction("scroll");
    }

    public disposeScroll(): void {
        jQuery(document).off("scroll", this.scrollHandler);
        this.hideScrollMessage();
    }

    public scrollHandler: () => void = (): void => {
        if ((window.innerHeight + window.scrollY) >= document.body.offsetHeight) {
            this.disposeScroll();
            this.showScrollMessageFunction("loading");
            this.page += 1;
            this.getData(this.sectionShown() === this.WORKORDER_STATUS.COMPLETED.id, this.page)
                .done((ls: any) => {
                    let actualLs: any[] = this.list();
                    actualLs = actualLs.concat(ls);
                    this.list(actualLs);
                })
                .fail(() => {
                    this.list([]);
                });
        }
    }

    public showScrollMessageFunction(msgId: string): void {
        if (msgId === "loading") {
            this.scrollMessageText(resx.localize("LoadingMoreResults"));
        } else {
            this.scrollMessageText(resx.localize("ScrollForMore"));
        }

        this.showScrollMessage(true);
    }

    public hideScrollMessage(): void {
        this.showScrollMessage(false);
    }

    public canActivate(): boolean | Redirect {
        if (!settingHelper.hasDispatchModel()) {
            notificationHelper.showWarning(resx.localize("DispatchModelRequired"));
            return new Redirect("Settings");
        }

        return true;
    }

    public activate(): JQuery.Deferred<any, any, any> {
        this.bindViewModel();
        return this.load();
    }

    public addWorkOrder(): void {
        const url: string = routerHelper.navigateTo("FieldService_Detail_Edit", 0) +
            routerHelper.addQuerystring({ editMode: "add", readonly: false });
        routerHelper.navigate(url);
    }

    public navigateToDetail(id: any): void {
        routerHelper.navigate(routerHelper.getRelativeUrl("", id));
    }

    public tabClicked: (status: any) => void = (status: any): void => {
        this.sectionShown(status.id);
        this.load();
    }

    public toggleOpenDrawer(drawer: any): void {
        drawer.isOpened(!drawer.isOpened());
    }

    public openAddress(data: any, event: any): void {
        event.cancelBubble = true;
        if (event.stopPropagation) {
            event.stopPropagation();
        }

        window.open(routerHelper.mapAddress(data.LocationAddress +
            ", " +
            data.LocationCity +
            " (" +
            data.LocationProvince +
            ") " +
            data.LocationPostalCode));
    }

    public getStatusLabel(isCompleted: boolean): string {
        return isCompleted ? resx.localize("Completed") : resx.localize("Ongoing");
    }
}
