import { autoinject } from "aurelia-framework";
import moment from "moment";
import templateService from "services/templateService";

@autoinject
export class AddUnavailability {

    public dispatchProjectCode: string = "";
    public dispatchDate!: Date;
    public employeeId: number = 0;

    public startDate: Date = new Date();
    public endDate: Date = new Date();
    public increment: number = 0;

    public async activate(params: any): Promise<any> {

        this.startDate = moment(params.startTime).toDate();
        this.endDate =  moment(params.endTime).toDate();
        this.employeeId = params.employeeId;

        this.increment = await templateService.getUserTemplateIncrement();
    }
}
