define([
    "knockout",
    "underscore",
    "core/resx",
    "helpers/notificationHelper",
    "helpers/routerHelper",
    "helpers/queryStringHelper",
    "services/productionService"
], function (ko, _, resx, notificationHelper, routerHelper, queryStringHelper, productionService) {
    "use strict";

    var viewModel = (function () {
        var self = null;

        function ctor() {
            self = this;

            //#region Properties
            self.resx = resx;

            self.dispatchProjectCode = ko.observable();
            self.dispatchDate = ko.observable();
            self.isProjectResponsible = false;
            self.productionsItems = ko.observableArray();
            self.readonly = true;
            //#endregion
        }

        //#region Private Functions
        function bindViewModel(dispatchProjectCode, dispatchDate, queryString) {
            self.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(queryString);
            self.readonly = queryStringHelper.parseReadonly(queryString);

            self.dispatchDate(dispatchDate);
            self.dispatchProjectCode(dispatchProjectCode);
        }

        function loadData() {
            return productionService.getProductionItemsForDailyEntry(self.dispatchProjectCode(), self.dispatchDate()).done(initialiseProductionsItems);
        }

        function initialiseProductionsItems(data) {
            self.readonly = data.IsReadOnly;
            self.productionsItems(data.ProductionEntries);
        }
        //#endregion

        //#region Public Functions
        ctor.prototype.activate = function (params) {
            bindViewModel(params.dispatchProjectCode, params.dailyEntryDate, params.q);

            return loadData();
        };

        ctor.prototype.genUrl = function(rel) {
            var url = routerHelper.getRelativeUrl(rel);
            url += routerHelper.addQuerystring({
                isProjectResponsible: self.isProjectResponsible,
                readonly: self.readonly
            });

            return url;
        };

        ctor.prototype.gotoEditPage = function() {
            routerHelper.navigate(this.genUrl('edit'));
        };

        ctor.prototype.deleteItem = function(id, line) {
            notificationHelper.showDeleteConfirmation()
                .done(function(success) {
                    if (success) {
                        routerHelper.showLoading();

                        productionService
                            .deleteProductionItem(self.dispatchProjectCode(), self.dispatchDate(), id, line)
                            .done(function(data) {
                                initialiseProductionsItems(data);
                            })
                            .always(function() {
                                routerHelper.hideLoading();
                            });
                    }
                });
        };
        //#endregion

        return ctor;
    })();

    return viewModel;
});
