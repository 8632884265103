import phoneHelper from "helpers/phoneHelper";
import { SettingRepository } from "repositories/setting-repository";
import { autoinject, bindable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import documentHelper from "helpers/documentHelper";
import employeeService from "services/employeeService";
import _ from "underscore";
import routerHelper from "helpers/routerHelper";
import moment from "moment";
import RouteRepository from "repositories/routeRepository";
import dateHelper from "helpers/dateHelper";

@autoinject
export class Employee {

    @bindable public readonly: boolean = false;
    @bindable public dispatchProjectCode: string = "";
    @bindable public dispatchDate: Date = new Date();
    @bindable public workOrderId: string = "";
    @bindable public dispatchId: number = 0;
    @bindable public employeeId: number = 0;
    public employee: any = {};
    public oneActiveCertificateHasDocument: boolean = false;
    public oneInactiveCertificateHasDocument: boolean = false;
    @bindable public isResponsible: boolean = false;
    public dateHelper: typeof dateHelper = dateHelper;

    public phoneHelper: typeof phoneHelper = phoneHelper;

    constructor(private readonly i18n: I18N, private readonly settingRepository: SettingRepository, private readonly routeRepository: RouteRepository) {
    }

    public async bind(): Promise<any> {
        await this.loadData();
    }

    public async loadData(): Promise<any> {
        this.mapLoadedData(await employeeService.getEmployeeFile(this.employeeId));
    }

    public getPicture(pictureCode: string): string {
        if (pictureCode === null) {
            return "url('images/avatar.png')";
        } else {
            return "url('data:image/jpg;base64," + pictureCode + "')";
        }
    }

    public mapLoadedData(data: any): any {
        data.avatar = this.getPicture(data.Picture);
        data.certificationActive = _.filter(data.Certifications, (certification: any) => !certification.Inactive);
        data.oneActiveCertificateHasDocument = _.filter(data.certificationActive, (certification: any) => certification.DocumentInfo !== null).length > 0;

        data.certificationInActive = _.filter(data.Certifications, (certification: any) => certification.Inactive);
        this.oneInactiveCertificateHasDocument = _.filter(data.certificationInActive, (certification: any) => certification.DocumentInfo !== null).length > 0;

        this.employee = data;
    }

    public getDocumentSize(size: number): string {
        return documentHelper.formatDocumentSize(size);
    }

    public getDocumentIcon(type: string): string {
        if (type) {
            return documentHelper.getIconClass(type);
        } else {
            return "";
        }
    }

    public getDocumentType(type: string): string {
        return this.i18n.tr(documentHelper.getLocalizationKey(type));
    }

    public getDownloadUrl(documentId: number): string {
        if (documentId) {
            let documentUrl = documentHelper.getDocumentUrlById(documentId);
            documentUrl += "?access_token=" + this.settingRepository.getToken();
            return documentUrl;
        } else {
            return "javascript:void(0);";
        }
    }
    public genUrl(route: string, param: any): string {
        let relativeUrl = "";

        if (param !== undefined) {
            relativeUrl = routerHelper.getRelativeUrl(route, param);
        } else {
            relativeUrl = routerHelper.getRelativeUrl(route);
        }

        return relativeUrl + routerHelper.addQuerystring({ isProjectResponsible: this.isResponsible, readonly: this.readonly });
    }

    public navigateTo(route: string, param: any): string {
        let url = routerHelper.navigateTo(route, this.dispatchProjectCode, this.dispatchDate, param);
        url += routerHelper.addQuerystring({ isProjectResponsible: this.isResponsible, readonly: this.readonly });
        return url;
    }

    public navigateToActivities(): void {
        const url = this.navigateTo("Project_Detail_Daily_Entry_TimeEntryEmployee", this.dispatchId);
        routerHelper.navigate(url, { replace: true, trigger: true });
    }

    public addUnavailability(): void {
        const params = {
            employeeId: this.employeeId,
            startTime: moment().format(),
            endTime: moment().format(),
        };
        routerHelper.navigateToRoute(this.routeRepository.routes.Calendar_AddUnavailability.name, params);
    }

    public getDocument(item: any, isSkill?: boolean): any {
        const t = item.DocumentsInfo && item.DocumentsInfo.length > 1 ?
            {
                name: this.i18n.tr("Documents"),
                size: "",
                icon: this.getDocumentIcon("FOLDER"),
                type: "",
                url: (routerHelper.getRelativeUrl(item.CertificationAssignmentId + ""))
                + routerHelper.addQuerystring({ isProjectResponsible: this.isResponsible, readonly: this.readonly })
            }
            :
            {
                id: item.DocumentsInfo.length ? item.DocumentsInfo[0].DocumentId : "",
                name: item.DocumentsInfo.length ? item.DocumentsInfo[0].FileName : "",
                size: item.DocumentsInfo.length ? this.getDocumentSize(item.DocumentsInfo[0].FileSize) : "",
                icon: item.DocumentsInfo.length ? this.getDocumentIcon(item.DocumentsInfo[0].Type) : isSkill ? "fa fa-cogs" : "fa fa-certificate",
                type: item.DocumentsInfo.length ? this.getDocumentType(item.DocumentsInfo[0].Type) : "",
                url: item.DocumentsInfo.length ? this.getDownloadUrl(item.DocumentsInfo[0].DocumentId) : "javascript:void(0)"
            };
        return t;
    }

    public addPicture(): void {
        const params = { employeeId: this.employeeId, dispatchProjectCode: this.dispatchProjectCode, dispatchDate: this.dispatchDate, workOrderId: this.workOrderId };
        routerHelper.navigateToRoute(this.routeRepository.routes.Employee_Profile_Picture.name, params);
    }

    public checkAndOpenDocument(item: any): void {
        const document = this.getDocument(item);
        documentHelper.openDocument(document);
    }

}
