import { I18N } from "aurelia-i18n";
import { Viewbag } from "helpers/view-bag";
import { autoinject, bindable, transient } from "aurelia-framework";
import RouteRepository from "repositories/routeRepository";

import { AdditionalFieldsSourceType } from "enums/additional-fields-source-type";

import routerHelper from "helpers/routerHelper";
import notificationHelper from "helpers/notificationHelper";
import { AdditionalFieldsHelper } from "helpers/additional-fields-helper";

import dailyEntryService from "services/dailyEntryService";
import defaultService from "services/defaultService";
import templateService from "services/templateService";
import { DialogCloseResult } from "aurelia-dialog";

@transient()
@autoinject
export class ProjectsCloseDayButton {

    @bindable public dispatchDate: any;
    @bindable public dispatchProjectCode: string = "";
    @bindable public allowEmployeeBreaksEntry: boolean = false;
    @bindable public navigateBackOnSave: boolean = false;
    @bindable public isInSummary: boolean = false;
    @bindable public reloadCallBack: any;
    @bindable public entry: any;
    public readonly: any;

    public lunchSelected: boolean = true;
    public breakSelected: boolean = true;
    public isNewLunch: boolean = false;
    public isNewBreak: boolean = false;

    constructor(private readonly i18n: I18N, private readonly routeRepository: RouteRepository, private readonly additionalFieldsHelper: AdditionalFieldsHelper, public viewbag: Viewbag) {

    }

    public bind(): any {

        if (this.reloadCallBack) {
            this.reloadCallBack(this.reload.bind(this));
        }

        if (!this.entry) {
            return this.loadData(this.dispatchProjectCode, this.dispatchDate);
        } else {
            this.mapDataFromApi(this.entry);
        }
    }

    public async loadData(dispatchProjectCode: string, dispatchDate: any): Promise<void> {
        await dailyEntryService.get(dispatchProjectCode, dispatchDate, await this.getTimeEntryMode(), await this.getInChargeRequireIndicateWhenEmployeeLeftWorksite(), false).then((entry: any) => {
            this.mapDataFromApi(entry);
        });
    }

    public genUrl(param: any): string {
        return routerHelper.navigateTo("Project_Detail_Daily_Entry", this.dispatchProjectCode, this.dispatchDate, param) + routerHelper.addQuerystring({ isProjectResponsible: true, readonly: false, lunchSelected: this.lunchSelected, breakSelected: this.breakSelected });
    }

    public mapDataFromApi(entry: any): void {
        this.entry = entry;
        this.readonly = entry.IsReadOnly;

        defaultService.setCurrentDispatchTemplateCode(entry.DispatchTemplate);
    }

    public handleAnswer(success: DialogCloseResult): void {
        if (!success.wasCancelled) {
            this.viewbag.value = null;
            if (success.output && !success.output.lunchSelected && !success.output.breakSelected) {
                this.callComplete(true);
            } else {
                if (success.output) {
                    this.lunchSelected = success.output.lunchSelected;
                    this.breakSelected = success.output.breakSelected;
                }

                if (this.navigateBackOnSave) {
                    routerHelper.navigate(this.genUrl("break"), { replace: true, trigger: true });
                } else {
                    routerHelper.navigate(this.genUrl("break"));
                }
            }
        } else {
            this.callComplete(true);
        }
    }

    public async showSingleBreaksChoice(): Promise<void> {
        const msg = this.isNewLunch ? "WantAddLunchTime" : "WantAddBreakTime";
        await notificationHelper.showDialogYesNo(this.i18n.tr(msg)).then((success: boolean ) => {
            this.handleAnswer({wasCancelled: !success});
        });
    }

    public async showMultipleBreaksChoice(): Promise<void> {
        await notificationHelper.showDialogProjectBreakTimesSelection(
            this.i18n.tr("WantAddBreakAndLunchTime"),
            "",
            {
                lunchSelected: this.lunchSelected,
                breakSelected: this.breakSelected,
                lunchVisible: this.isNewLunch,
                breakVisible: this.isNewBreak
            }).then((success: any) => this.handleAnswer(success));
    }

    public async handleBreakHours(data: any): Promise<void> {
        this.isNewLunch = data.GotLunchHours;
        this.isNewBreak = data.GotBreakHours;

        if (this.isNewLunch && this.isNewBreak) {
            await this.showMultipleBreaksChoice();
        } else {
            await this.showSingleBreaksChoice();
        }
    }

    public async callComplete(forceSave: boolean): Promise<void> {
        const data = await dailyEntryService.complete(this.dispatchProjectCode, this.dispatchDate, forceSave, null);

        if (data.GotBreakHours || data.GotLunchHours) {
            if (this.entry.ManualBreaksManagementEnabled) {
                routerHelper.navigateToRoute(this.routeRepository.routes.Project_Detail_Daily_Entry_Manage_Lunch_Breaks.name, {
                    readonly: this.readonly,
                    dispatchDate: this.dispatchDate,
                    dailyEntryDate: this.dispatchDate,
                    dispatchProjectCode: this.dispatchProjectCode,
                    isClosingDayMode: true
                });
            } else {
                await this.handleBreakHours(data);
            }

        } else {
            if (data.Status === "5") {
                if (this.navigateBackOnSave) {
                    routerHelper.navigateBack();
                } else {
                    routerHelper.navigateSelf();
                }
            }
        }

    }

    public showNextStepToDo(): void {
        const status = this.entry.ReadyToComplete;
        let message = "";

        if (!status.AllRequiredAdditionalFieldsAreEntered) {
            message = this.additionalFieldsHelper.getRequiredRessourceBySourceType(AdditionalFieldsSourceType.DailyEntry);
        } else if (status.UndefinedPresenceAttendancesCount) {
            message = this.i18n.tr("msg_DailyEntry_TakeAttendanceForXEmployees").replace("{0}", status.UndefinedPresenceAttendancesCount);
        } else if (!status.AttendancesWithTimeEntryStartedCount) {
            message = this.i18n.tr("msg_DailyEntry_EnterTimeForXEmployees").replace("{0}", status.PresentAttendancesCount);
        } else if (status.CompletedAttendancesCount < status.PresentAttendancesCount) {
            if (this.isInSummary) {
                message = this.i18n.tr("msg_DailyEntry_QuitEmployeeToClose");
            } else {
                message = this.i18n.tr("msg_DailyEntry_GoToHoursSummrayToQuitEmployee");
            }
        }

        notificationHelper.showWarning(message, undefined, { timeOut: 0 });
    }

    public reload(): void {
        this.loadData(this.dispatchProjectCode, this.dispatchDate);
    }

    public async complete(): Promise<void> {
        if (this.entry.ReadyToComplete.IsReadyToComplete) {
            await this.callComplete(false);
        } else {
            this.showNextStepToDo();
        }
    }

    public async uncomplete(): Promise<void> {
        const data = await dailyEntryService.uncomplete(this.dispatchProjectCode, this.dispatchDate);
        if (data.Status === "2") {
            routerHelper.navigateSelf();
        }
    }

    public async getTimeEntryMode(): Promise<string> {
        return await templateService.getCurrentTemplateConfigs().ProjectTimeEntryMode;
    }

    public async getInChargeRequireIndicateWhenEmployeeLeftWorksite(): Promise<boolean> {
        return await templateService.getCurrentTemplateConfigs().InChargeRequireIndicateWhenEmployeeLeftWorksite;
    }
}
